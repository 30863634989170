@use '../abstracts/mixins' as *;
@use '../abstracts/variables' as *;

/**
 * Poppins
 */
 @font-face {
    font-family: 'Poppins';
    src: url('/assets/fonts/Poppins/Poppins-Thin.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-ExtraLight.otf') format('opentype');
    font-weight: 200;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-ExtraLightItalic.otf') format('opentype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-MediumItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-SemiBold.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-SemiBoldItalic.otf') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-Black.otf') format('opentype');
    font-weight: 800;
}

@font-face {
    font-family: 'Poppins';
    src: url('assets/fonts/Poppins/Poppins-BlackItalic.otf') format('opentype');
    font-weight: 800;
    font-style: italic;
}

/**
 * Advent Pro
 */
@font-face {
    font-family: 'Advent Pro';
    src: url('/assets/fonts/Advent_Pro/AdventPro-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: 'Advent Pro';
    src: url('/assets/fonts/Advent_Pro/AdventPro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Advent Pro';
    src: url('/assets/fonts/Advent_Pro/AdventPro-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Advent Pro';
    src: url('/assets/fonts/Advent_Pro/AdventPro-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Advent Pro';
    src: url('/assets/fonts/Advent_Pro/AdventPro-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Advent Pro';
    src: url('/assets/fonts/Advent_Pro/AdventPro-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Advent Pro';
    src: url('/assets/fonts/Advent_Pro/AdventPro-Bold.ttf') format('truetype');
    font-weight: 700;
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1;
    color: $color-grey-dark-1;
    background-color: $color-black;
}

body * {
    font-size: 1.8rem;
    font-weight: 500;
}

h1 {
    font-size: xx-large;
    letter-spacing: 0.5rem;
}

h2 {
    font-size: x-large;
    letter-spacing: 0.4rem;
}

h3 {
    font-size: large;
}

h4 {
    font-size:medium
}

h5 {
    font-size: small;
}

h6 {
    font-size: x-small;
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;

    backface-visibility: hidden;
    margin-bottom: 6rem;

    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.5rem;

        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;

        @include respond(phone) {
            letter-spacing: 1rem;
            font-family: 5rem;
        }
        /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
    }

    &--sub {
        display: block;
        font-size: 2rem;
        font-weight: 700;
        letter-spacing: 1.75rem;
        animation: moveInRight 1s ease-out;

        @include respond(phone) {
            letter-spacing: 0.5rem;
        }
    }
}

.heading-secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 0.2rem;
    transition: all 0.2s;

    @include respond(tab-port) {
        font-size: 3rem;
    }

    @include respond(phone) {
        font-size: 2.5rem;
    }

    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: 0.5rem 1rem 2rem rgba($color-black, 0.2);
    }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;

    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}
