@use '../abstracts/variables' as *;

.app-header {
    font-family: 'Advent Pro';
    text-align: center;

    h1 {
        text-transform: capitalize;
        display: inline-block;
    }

    .first-name {
        text-transform: uppercase;
        color: $color-grey-light-1;
    }

    .e-menu-wrapper {
        display: inline-block;
        cursor: pointer;
        vertical-align: top;
        width: 3rem;
        height: 3rem;
        margin: .7rem 1.5rem 0.7rem 0;
    }

    span.e-menu {
        position: relative;
        width: 2rem;

        &,
        &::before,
        &::after {
            display: inline-block;
            // background-color: $color-grey-light-1;
            background-color: $color-red;
            height: .4rem;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 3rem;
            left: 0;
        }

        &::before {
            top: -1rem;
        }

        &::after {
            top: 1rem;
        }
    }
}
